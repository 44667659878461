import React, { useState } from "react"

export default function SectionContact() {
  const [contact, setContact] = useState({})
  const [submited, setSubmited] = useState(false)
  const [submiting, setSubmiting] = useState(false)
  const [showErr, setShowErr] = useState(false)

  const submitContact = () => {
    setShowErr(true)
    if (!contactFilled()) return
    setSubmiting(true)
    setShowErr(true)

    var myHeaders = new Headers()
    myHeaders.append("Content-Type", "application/json")

    var body = JSON.stringify({
      name: contact.name,
      email: contact.email,
      message: contact.msg,
      company: contact.company,
      phone: contact.phone,
      subject: "Request From SysDone Web",
    })

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body,
    }

    fetch(
      "https://us-central1-sysdone-com.cloudfunctions.net/sysdone-emails",
      requestOptions
    )
      .then(async res => {
        if (res.status !== 200) {
          setSubmiting(false)
          return alert(await res.text())
        }
        setSubmiting(false)
        setSubmited(true)
      })
      .catch(error => alert(error))
  }

  const contactFilled = () => {
    return contact.name && contact.email && contact.msg ? true : false
  }

  const Submited = () => (
    <div className="submited-div">
      <img
        src={require("../../img/sysdone-ani-icon.gif")}
        className="ani-logo"
        alt="ani-logo"
      />
      <h1 className="title title-blue">Your submission was successful!</h1>
      <p className="p-blue">
        Thanks for submitting your contact info.
        <br />
        One of our professional staff will contact you as soon as possible.
        <br />
        <br />
        Thank you.
      </p>
    </div>
  )

  return (
    <div id="contact">
      <h1 className="title title-white">
        Put the gears into motion by reaching out to&nbsp;us.
      </h1>
      {submited ? (
        <Submited />
      ) : (
        <div className="contact-div">
          <input
            type="name"
            placeholder="Your name *"
            value={contact.name}
            onChange={e => setContact({ ...contact, name: e.target.value })}
            className={`sys-input ${
              showErr && !contact.name ? "sys-input-rq" : ""
            }`}
          />
          <input
            type="name"
            placeholder="Your company"
            value={contact.company}
            onChange={e => setContact({ ...contact, company: e.target.value })}
            className={`sys-input`}
          />
          <input
            type="phone"
            placeholder="Your phone number"
            value={contact.phone}
            onChange={e => setContact({ ...contact, phone: e.target.value })}
            className={`sys-input`}
          />
          <input
            type="email"
            pattern="/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/"
            placeholder="Your email *"
            value={contact.email}
            onChange={e => setContact({ ...contact, email: e.target.value })}
            className={`sys-input ${
              showErr && !contact.email ? "sys-input-rq" : ""
            }`}
          />
          <textarea
            type="message"
            placeholder="Your message *"
            value={contact.msg}
            onChange={e => setContact({ ...contact, msg: e.target.value })}
            className={`sys-input sys-text ${
              showErr && !contact.msg ? "sys-input-rq" : ""
            }`}
          />
          <div className="contact-button">
            <button
              data-sal="zoom-in"
              data-sal-delay="200"
              data-sal-easing="ease"
              data-sal-duration="1200"
              type="submit"
              onClick={submitContact}
              className="btn btn-primary"
              disabled={submiting}
            >
              {submiting ? "Submiting..." : "Get In Touch"}
            </button>
          </div>
        </div>
      )}
    </div>
  )
}
